import React from 'react';
import './styles.css';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

const HomeRecentArticles = ({ data }) => {
  return (
    <>
      <div className='container-article'>
        <LazyLoadImage src={require(`../../assets/img/promotion/separador.png`)}
          alt='evento'
          width={"100%"} />
        <h1 className='home-the-most-viewed-title'>{data?.information.title1}</h1>
        <div className='content-article'>
          <LazyLoadImage
            src='https://upload.wikimedia.org/wikipedia/commons/3/3b/2020-02-11_Teotihuacan_la_Avenida_de_los_Muertos_y_la_Pir%C3%A1mide_del_Sol.jpg'
            /*src={require(`../../assets/img/descargas/hero.webp`)}*/
            alt='evento'
            className="image-article" />
          <p className='text-article'>
            <h2 className='title-article'>{data?.information.subtitle1}</h2>
            {data?.information.text1}
          </p>
        </div>
      </div>
      <div className='container-article'>
        <h1 className='home-the-most-viewed-title'>{data?.information.title2}</h1>
        <div className='content-article'>
          <p className='text-article-left'>
            <h2 className='title-article'>{data?.information.subtitle2}</h2>
            <p>{data?.information.text2}</p>
            <p>{data?.information.text3}</p>
            <p>{data?.information.text4}</p>
          </p>
          <div>
            <div className='imageGrid'>
              <LazyLoadImage src={require(`../../assets/img/promotion/CulturaMayaCuadrado.jpg`)}
                alt='evento'
                className='PM' />
              {/* <div className="leftSide">
                <LazyLoadImage src={require(`../../assets/img/promotion/PuntoMexico1.jpeg`)}
                  alt='evento'
                  className='image-grid' />
              </div>
              <LazyLoadImage src={require(`../../assets/img/promotion/PuntoMexico2.jpeg`)}
                alt='evento'
                className='image-grid' />
              <LazyLoadImage src={require(`../../assets/img/promotion/PuntoMexico3.jpeg`)}
                alt='evento'
                className='image-grid' /> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default HomeRecentArticles;